
ul.entities {
	li {
		padding: 10px;

		a {
			line-height: 25px;
		}
	}
}
